import React from 'react';
import { Link } from 'react-scroll';
import logo from "../pics/logo.png"

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg sticky-top my_navbar">
            <div className="container-fluid pt-3 check2">
                <img src={logo} alt="pic" height="60" className="logo" />
                <button className="nav_btn navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse content" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 lg-0 ms-4">
                        <li className="nav-item mx-1">
                            <Link className="nav-link active" aria-current="page" to="/" spy={true} smooth={true} offset={-100} duration={500} >HOME</Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link className="nav-link" to="services" spy={true} smooth={true} offset={-100} duration={500} >FEATURE</Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link className="nav-link" to="content" spy={true} smooth={true} offset={-100} duration={500} >WE OFFER</Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link className="nav-link" to="customer" spy={true} smooth={true} offset={-100} duration={500} >ABOUT</Link>
                        </li>
                        <li className="nav-item mx-1 nav_content">
                            <Link className="nav-link" to="forms" spy={true} smooth={true} offset={-100} duration={500} >CONTACT</Link>
                        </li>
                        {/* <li className="nav-item dropdown mx-1 nav_content">
                            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                PAGES
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="#">Generic</Link></li>
                                <li><Link className="dropdown-item" to="#">Element</Link></li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
