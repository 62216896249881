import React from 'react'

function CustomerSupport() {
  return (
    <div className="container-fluid" id="customer">
        <div className="row">
        <div className="col-lg-6 col-md-12 cs_left">
        </div>
        <div className="col-lg-6 col-md-12 cs_right">
            <div className="col-md-7">
            <h1 className='cs_h1'>Responsible
            Customer Support</h1>
            <p className='cs_p'>At Auroxa Communication, we believe in taking full responsibility for our clients' needs. Our support team is always ready to provide timely solutions and assistance, ensuring a smooth experience. You can rely on us for trustworthy guidance every step of the way.</p>
            <div className="btn cs_btn">LEARN MORE<span className='cs_icon'><i className="fa-solid fa-arrow-right-long"></i></span></div>
            </div>
        </div>
        </div>
    </div>
  )
}

export default CustomerSupport
