import React from 'react'


function FooterSection() {
  return (
    <div className="container-fluid bg-dark">
        <div className="row f_row1">
            <div className="col-md-3 f_about">
                <h4 className='f_h4'>About Us</h4>
                <p className='f_p'>At Auroxa Communication, we are dedicated to providing innovative communication solutions that empower businesses and foster meaningful connections, ensuring success every step of the wa</p>
            </div>
            <div className="col-md-4 f_contact">
                <h4 className='f_h4'>Contact Us</h4>
                <p className='f_p'>We’re here to assist you! Reach out to Auroxa Communication for any inquiries, and let’s start building a brighter future together.</p>
                <p className='f_p2'>0303-5699010<br/>0334-5815277</p>
            </div>
            <div className="col-md-5 f_news">
                <h4 className='f_h4'>Newsletter</h4>
                <p className='f_p'>We value your time and promise to only send exclusive offers—no spam, just valuable insights delivered straight to your inbox.</p>
                <form >
                    <input className='last_mail' type="email" placeholder='Email address' />
                </form>
            </div>
        </div>
        <div className="row f_row2">
            <div className="col-md-8">
                <p className='f_p_l'>Copyright ©2024 All rights reserved | Powered by <span  className='last_span'>Auroxa tech</span> </p>
            </div>
            <div className="col-md-2 d-flex align-items-center">
                <a href="https://www.facebook.com/auroxatech/"><i className="fa-brands f_icon fa-square-facebook mx-2  "></i></a>
                <a href="https://api.whatsapp.com/send/?phone=%2B923345815277&text&type=phone_number&app_absent=0"><i className="fa-brands f_icon fa-square-whatsapp mx-2 "></i></a>
            </div>
        </div>
    </div>
  )
}

export default FooterSection
